import { inject, injectable } from 'inversify'
import { action, computed, observable } from 'mobx'
import symbols from '@/symbols'
import {
  DbPlan,
  IAngelBase,
  IAngelListInputBase,
  IAttackListEntityBase,
  ICorporateInvestorBase,
  IToggleAngelListUseCase,
  IToggleCorporateInvestorListUseCase,
  IUpdateAngelListUseCase,
  IUpdateCorporateInvestorListUseCase,
  IViewer,
  IViewerBase,
  ICorporateInvestorListInputBase,
  IUserMemberBase,
  UserMemberRole,
  IUserBase,
  IDashboardBase,
  IFetchDashboardUseCase,
  FetchDashboardUseCaseInput,
  FetchDashboardUseCaseOutput,
  ICommentBase,
  IAddCommentToAngelUseCase,
  IAddCommentToCorporateInvestorUseCase,
  IRemoveCommentToAngelUseCase,
  IRemoveCommentToCorporateInvestorUseCase,
  IUpdateCommentToAngelUseCase,
  IUpdateCommentToCorporateInvestorUseCase,
  IAddCorporateInvestorListsUseCase,
  AddCorporateInvestorListsUseCaseIntput,
  AddCorporateInvestorListsUseCaseOutpt,
  RemoveAttackListsUseCaseInput,
  RemoveAttackListsUseCaseOutput,
  IRemoveAttackListsUseCase,
  IAddAngelListsUseCase,
  AddAngelListsUseCaseInput,
  AddAngelListsUseCaseOutput,
  IUserProfileBase,
  IUserProfileFactory,
  UserProfileKindBase,
  BillingPortalBase,
  DbSubscriptionInputBase,
  PaymentIntentBase,
  IErrorsStore,
  CreateCheckoutSessionInputBase,
  CheckoutSessionBase,
  IUserProfile,
} from '@/types'
import {
  ICreateBillingPortalSessionUseCase,
  ICreateDbSubscriptionUseCase,
  ICreateCheckoutSessionUseCase,
} from '@/types/useCases/subscriptions'

@injectable()
export default class Viewer implements IViewer {
  @observable id = ''

  @observable email: string = null

  @observable username: string = null

  @observable profile: IUserProfileBase = null

  @observable name: string = null

  @observable dbPlan = DbPlan.NO_CONTRACT

  @observable attackListCount: number

  @observable completedInterviewCount: number

  @observable completedMailCount: number

  @observable userOwners: IUserMemberBase[] = []

  @observable dashboard: IDashboardBase

  @observable hasStripeCustomerId = false

  @observable isFirstSignIn = false

  constructor(
    @inject(symbols.IToggleCorporateInvestorListUseCase)
    private toggleCorporateInvestorListUseCase: IToggleCorporateInvestorListUseCase,
    @inject(symbols.IToggleAngelListUseCase) private toggleAngelListUseCase: IToggleAngelListUseCase,
    @inject(symbols.IAddCorporateInvestorListsUseCase)
    private addCorporateInvestorListsUseCase: IAddCorporateInvestorListsUseCase,
    @inject(symbols.IAddAngelListsUseCase)
    private addAngelListsUseCase: IAddAngelListsUseCase,
    @inject(symbols.IUpdateCorporateInvestorListUseCase)
    private updateCorporateInvestorListUseCase: IUpdateCorporateInvestorListUseCase,
    @inject(symbols.IUpdateAngelListUseCase) private updateAngelListUseCase: IUpdateAngelListUseCase,
    @inject(symbols.IFetchDashboardUseCase) private fetchDashboardUseCase: IFetchDashboardUseCase,
    @inject(symbols.IAddCommentToCorporateInvestorUseCase)
    private addCommentToCorporateInvestorUseCase: IAddCommentToCorporateInvestorUseCase,
    @inject(symbols.IUpdateCommentToCorporateInvestorUseCase)
    private updateCommentToCorporateInvestorUseCase: IUpdateCommentToCorporateInvestorUseCase,
    @inject(symbols.IRemoveCommentToCorporateInvestorUseCase)
    private removeCommentToCorporateInvestorUseCase: IRemoveCommentToCorporateInvestorUseCase,
    @inject(symbols.IAddCommentToAngelUseCase) private addCommentToAngelUseCase: IAddCommentToAngelUseCase,
    @inject(symbols.IUpdateCommentToAngelUseCase) private updateCommentToAngelUseCase: IUpdateCommentToAngelUseCase,
    @inject(symbols.IRemoveCommentToAngelUseCase) private removeCommentToAngelUseCase: IRemoveCommentToAngelUseCase,
    @inject(symbols.IRemoveAttackListsUseCase) private removeAttackListsUseCase: IRemoveAttackListsUseCase,
    @inject(symbols.IUserProfileFactory) private userProfileFactory: IUserProfileFactory,
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.ICreateBillingPortalSessionUseCase)
    private createBillingPortalSessionUseCase: ICreateBillingPortalSessionUseCase,
    @inject(symbols.ICreateDbSubscriptionUseCase) private createDbSubscriptionUseCase: ICreateDbSubscriptionUseCase,
    @inject(symbols.ICreateCheckoutSessionUseCase) private createCheckoutSessionUseCase: ICreateCheckoutSessionUseCase
  ) {}

  @action
  update(base: IViewerBase): void {
    // required fields
    this.id = base.id
    this.email = base.email
    this.username = base.username
    this.name = base.name
    this.attackListCount = base.attackListCount
    this.completedInterviewCount = base.completedInterviewCount
    this.completedMailCount = base.completedMailCount
    this.userOwners = base.userOwners
    this.hasStripeCustomerId = base.hasStripeCustomerId

    if (typeof base?.isFirstSignIn === 'boolean') {
      this.isFirstSignIn = base.isFirstSignIn
    }

    if (base?.dbPlan) {
      this.dbPlan = base.dbPlan
    }

    if (base?.profile) {
      // UserProfile に Factory を作成して割り当て
      this.profile = this.userProfileFactory.create({ base: base.profile })
    }
  }

  @action
  updateName(name: string): void {
    this.name = name
  }

  @action
  updateUsername(username: string): void {
    this.username = username
  }

  @action
  _updateDashboard(dashboard: IDashboardBase): void {
    this.dashboard = dashboard
  }

  @action
  updateUserMemberRole(targetUsername: string, role: UserMemberRole): void {
    this.userOwners = this.userOwners.map((uo) => {
      if (uo.owner?.username === targetUsername) {
        uo.role = role
      }
      return uo
    })
  }

  @action
  updateProfile(profile: IUserProfile): void {
    this.profile = profile
  }

  @computed
  get isVC(): boolean {
    if (this.profile?.kind === UserProfileKindBase.VC) {
      return true
    }
    return false
  }

  @computed
  get hasActiveDbSubscription(): boolean {
    return this.dbPlan === DbPlan.BASIC_PLAN
  }

  async toggleCorporateInvestorList(slug: string, targetUsername: string): Promise<ICorporateInvestorBase> {
    const output = await this.toggleCorporateInvestorListUseCase.handle({ slug, targetUsername })

    if (output.isSuccessful) {
      return output.data.corporateInvestor
    }

    return null
  }

  async toggleAngelList(slug: string, targetUsername: string): Promise<IAngelBase> {
    const output = await this.toggleAngelListUseCase.handle({ slug, targetUsername })

    if (output.isSuccessful) {
      return output.data.angel
    }

    return null
  }

  async addCorporateInvestorLists(
    input: AddCorporateInvestorListsUseCaseIntput
  ): Promise<AddCorporateInvestorListsUseCaseOutpt> {
    const output = await this.addCorporateInvestorListsUseCase.handle(input)

    return output
  }

  async addAngelLists(input: AddAngelListsUseCaseInput): Promise<AddAngelListsUseCaseOutput> {
    const output = await this.addAngelListsUseCase.handle(input)

    return output
  }

  async updateCorporateInvestorList(
    id: string,
    input: ICorporateInvestorListInputBase,
    targetUsername: string
  ): Promise<IAttackListEntityBase> {
    const output = await this.updateCorporateInvestorListUseCase.handle({
      id,
      corporateInvestorList: input,
      targetUsername,
    })

    if (output.isSuccessful) {
      return output.data.attackListEntity
    }

    return null
  }

  async updateAngelList(
    id: string,
    input: IAngelListInputBase,
    targetUsername: string
  ): Promise<IAttackListEntityBase> {
    const output = await this.updateAngelListUseCase.handle({ id, angelList: input, targetUsername })

    if (output.isSuccessful) {
      return output.data.attackListEntity
    }

    return null
  }

  getAttackListOwner(targetUsername: string): IUserBase {
    if (targetUsername === this.username) {
      return this
    }
    const owner = this.userOwners.find((o) => o?.owner?.username === targetUsername)
    return owner?.owner
  }

  showableAttackList(targetUsername: string): boolean {
    if (targetUsername === this.username) {
      return this.dbPlan === DbPlan.BASIC_PLAN
    }
    const owner = this.getAttackListOwner(targetUsername)
    if (owner) {
      return true
    }
    return false
  }

  settingableAttackList(targetUsername: string): boolean {
    if (targetUsername === this.username) {
      return this.dbPlan === DbPlan.BASIC_PLAN
    }
    const owner = this.userOwners.find((o) => o?.owner?.username === targetUsername)
    if (owner) {
      if (owner?.role === UserMemberRole.ADMIN) {
        return true
      }
      return false
    }
    return false
  }

  async fetchDashboard(input: FetchDashboardUseCaseInput): Promise<FetchDashboardUseCaseOutput> {
    const output = await this.fetchDashboardUseCase.handle(input)

    if (output.isSuccessful) {
      this._updateDashboard(output.data.dashboard)
    } else {
      this._updateDashboard(null)
    }

    return output
  }

  async addCommentToCorporateInvestor(slug: string, body: string): Promise<ICommentBase> {
    const output = await this.addCommentToCorporateInvestorUseCase.handle({ slug, body })
    if (output.isSuccessful) {
      return output.data.comment
    }

    return null
  }

  async updateCommentToCorporateInvestor(id: string, body: string): Promise<ICommentBase> {
    const output = await this.updateCommentToCorporateInvestorUseCase.handle({ id, body })
    if (output.isSuccessful) {
      return output.data.comment
    }

    return null
  }

  async removeCommentToCorporateInvestor(id: string): Promise<ICommentBase> {
    const output = await this.removeCommentToCorporateInvestorUseCase.handle({ id })
    if (output.isSuccessful) {
      return output.data.comment
    }

    return null
  }

  async addCommentToAngel(slug: string, body: string): Promise<ICommentBase> {
    const output = await this.addCommentToAngelUseCase.handle({ slug, body })
    if (output.isSuccessful) {
      return output.data.comment
    }

    return null
  }

  async updateCommentToAngel(id: string, body: string): Promise<ICommentBase> {
    const output = await this.updateCommentToAngelUseCase.handle({ id, body })
    if (output.isSuccessful) {
      return output.data.comment
    }

    return null
  }

  async removeCommentToAngel(id: string): Promise<ICommentBase> {
    const output = await this.removeCommentToAngelUseCase.handle({ id })
    if (output.isSuccessful) {
      return output.data.comment
    }

    return null
  }

  async removeAttackLists(input: RemoveAttackListsUseCaseInput): Promise<RemoveAttackListsUseCaseOutput> {
    const output = await this.removeAttackListsUseCase.handle(input)

    return output
  }

  async createBillingPortalSession(): Promise<BillingPortalBase> {
    const output = await this.createBillingPortalSessionUseCase.handle({ userId: this.id })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.billingPortal
  }

  async createDbSubscription(input: DbSubscriptionInputBase): Promise<PaymentIntentBase> {
    const output = await this.createDbSubscriptionUseCase.handle({
      userId: this.id,
      attributes: input,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.paymentIntent
  }

  async createCheckoutSession(input: CreateCheckoutSessionInputBase): Promise<CheckoutSessionBase> {
    const output = await this.createCheckoutSessionUseCase.handle({ userId: input.userId, priceType: input.priceType })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.sessionUrl
  }
}
