import { observable } from 'mobx'
import { IUserProfileBase, UserProfileKindBase } from '@/types'

export default class UserProfile implements IUserProfileBase {
  @observable avatar: string

  @observable kind: UserProfileKindBase

  @observable companyName: string

  @observable jobTitle: string

  constructor(base: IUserProfileBase) {
    this.avatar = base.avatar
    this.kind = base.kind
    this.companyName = base.companyName
    this.jobTitle = base.jobTitle
  }
}
