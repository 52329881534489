import { inject, injectable } from 'inversify'
import fetch from 'cross-fetch'
import { GraphQLClient } from 'graphql-request'
import {
  AddAngelListsRequestInput,
  AddCommentToAngelRequestInput,
  AddCommentToCorporateInvestorRequestInput,
  AddCorporateInvestorListsRequestInput,
  AddMemberInvitationRequestInput,
  AddUserMemberRequestInput,
  FetchAngelRequestInput,
  FetchAngelsRequestInput,
  FetchAngelsRequestOutput,
  FetchAttackListsRequestInput,
  FetchAttackListsRequestOutput,
  FetchCommentsRequestInput,
  FetchCommentsRequestOutput,
  FetchCorporateInvestorRequestInput,
  FetchCorporateInvestorsRequestInput,
  FetchCorporateInvestorsRequestOutput,
  FetchDashboardRequestInput,
  FetchLocationsStoreInput,
  FetchUserMembersRequestInput,
  IAddedListBase,
  IAngelBase,
  IAngelsService,
  IAppCredentials,
  IAttackListEntityBase,
  ICommentBase,
  ICommentsService,
  ICorporateInvestorBase,
  ICorporateInvestorsService,
  IDashboardBase,
  IInvitationBase,
  IInvitationSentBase,
  IInvitationsService,
  ILocationBase,
  ILocationsService,
  IMarketBase,
  IMarketsService,
  IPreferences,
  IRemovedListBase,
  IRoundBase,
  IRoundsService,
  ISubscriptionsService,
  IUserBase,
  IUserMemberBase,
  IUserMembersService,
  IUserProfileBase,
  IUserProfileInputBase,
  IUsersService,
  IViewerBase,
  IViewerService,
  RemoveAttackListsRequestInput,
  RemoveCommentToAngelRequestInput,
  RemoveCommentToCorporateInvestorRequestInput,
  RemoveUserMemberRequestInput,
  SearchUserByEmailRequestInput,
  ToggleAngelListRequestInput,
  ToggleCorporateInvestorListRequestInput,
  UpdateAngelListRequestInput,
  UpdateCommentToAngelRequestInput,
  UpdateCommentToCorporateInvestorRequestInput,
  UpdateCorporateInvestorListRequestInput,
  UpdateMeRequestInput,
  UpdateUserMemberRequestInput,
  ValidInvitationTokenRequestInput,
} from '@/types'
import { calcAfterValue, calcCurrentPage, isBrowser } from '@/utils'
import {
  BillingPortalBase,
  CreateBillingPortalSessionInputBase,
  CreateDbSubscriptionInputBase,
  CreateCheckoutSessionInputBase,
  PaymentIntentBase,
  CheckoutSessionBase,
  getSdk,
} from '@/lib/generated/sdk'
import symbols from '@/symbols'
import UnauthenticatedError from '@/errors/UnauthenticatedError'
import RequestTimeoutError from '@/errors/RequestTimeoutError'

@injectable()
export default class AppAPIGateway
  implements
    IViewerService,
    IMarketsService,
    ICorporateInvestorsService,
    IRoundsService,
    IAngelsService,
    IUserMembersService,
    IUsersService,
    ICommentsService,
    IInvitationsService,
    ISubscriptionsService,
    ILocationsService {
  @inject(symbols.IAppCredentials) private credentials: IAppCredentials

  @inject(symbols.IPreferences) private preferences: IPreferences

  private sdk: ReturnType<typeof getSdk> = null

  // setTimeout の delay オプションに使用
  private readonly _timeoutDelay = 15000

  private _fetchCorporateInvestorsCursors = ''

  private _fetchAngelsCursors = ''

  private _fetchAttackListsCursors = ''

  private _fetchCommentsCursors = ''

  constructor() {
    const url = `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/graphql`
    const client = new GraphQLClient(url, {
      // fetch をラップして access-token, uid をインターセプトする処理
      fetch: async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        let response: Response

        const abortController = new AbortController()
        const timeoutID = setTimeout(() => {
          abortController.abort()
        }, this._timeoutDelay)

        try {
          response = await fetch(input, { ...init, signal: abortController.signal })
          // 送ったトークンの有効性を検証
          if (response.status !== 500) {
            // 500 エラーのケースでは uid は返って来ないのでログアウトさせられてしまうため 500 以外のケースを処理
            const oldAccessToken = init.headers['access-token'] as string
            const uid = response.headers.get('uid')
            this._handleUnauthenticated(oldAccessToken, uid)
          }

          // 送ったトークンが valid なら新しいものを保存
          const newAccessToken = response.headers.get('access-token')
          this._setNewAccessToken(newAccessToken)
        } catch (e) {
          // TODO: eslint-disable-next-line を削除
          // e instanceof DOMException && e.name == "AbortError" を条件文にすると ReferenceError: DOMException is not defined エラーが表示されるので、一旦後半の条件文のみ使用
          // eslint-disable-next-line consistent-return,@typescript-eslint/no-unsafe-member-access
          if (e.name === 'AbortError') {
            // 既知の問題として
            // タイムアウト時にはトークンが更新できない。
            // サーバー側の `batch_request_buffer_throttle` で設定している時間（1分）が過ぎると
            // トークンが無効になり、フロントでは強制ログアウトになる。
            // ただ、タイムアウトを設定しない場合も、サーバーからレスポンスを待たずリクエストを中止した場合や
            // そもそもレスポンスが返ってこない場合にはトークンが更新できないため、
            // トークン更新は諦めてタイムアウトをハンドリングする方を選択した仕様になっている。
            throw new RequestTimeoutError('リクエストがタイムアウトしました。時間をおいて再度お試しください。', {
              input,
              init,
            })
          }

          // 他のエラーを握りつぶさないように再度スロー
          throw e
        } finally {
          // 正常時もエラーを投げたときもタイムアウト用のタイマーをクリア
          clearTimeout(timeoutID)
        }

        return response
      },
    })

    this.sdk = getSdk(client)
  }

  private _setNewAccessToken(newAccessToken: string): void {
    // 同時に複数 post した場合、新しい access-token はひとつのレスポンスにしか返ってこないため
    // access-token が空でないときのみ値を更新する
    if (isBrowser() && newAccessToken) {
      this.credentials.updateAccessToken(newAccessToken)
    }
  }

  // ログイン済で accessToken をつけてるのに uid が返ってこないのは
  // 保持してる accessToken が期限切れのためなので認証エラーにする
  private _handleUnauthenticated(oldAccessToken: string, uid: string | null): void {
    if (!oldAccessToken) {
      // oldAccessToken がないのはログインしてないケース
      return
    }

    if (uid) {
      // uid が返ってくるのは API に渡した oldAccessToken が有効なケース
      return
    }

    throw new UnauthenticatedError('Uid is empty on Response Header.')
  }

  // API へリクエストする際に使用する headers を返すメソッド
  private _headers(): HeadersInit {
    const credentials = this.credentials.getLatestCredentials()
    return {
      'X-PREFERRED-LANGUAGE': this.preferences.language,
      'access-token': credentials.accessToken || '',
      uid: credentials.uid || '',
      client: credentials.client || '',
    }
  }

  // ============================================================
  // Viewer
  // ============================================================
  async fetchMe(): Promise<IViewerBase> {
    const response = await this.sdk.fetchMe({}, this._headers())

    return response.me
  }

  async updateMe(input: UpdateMeRequestInput): Promise<IViewerBase> {
    const response = await this.sdk.updateMe(
      {
        input: {
          attributes: input,
        },
      },
      this._headers()
    )

    return response.updateMe
  }

  async fetchAttackLists(input: FetchAttackListsRequestInput): Promise<FetchAttackListsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else if (input.page) {
      after = calcAfterValue(input.page, input.limit)
    } else {
      after = this._fetchAttackListsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      query: input.searchWord ? input.searchWord : '',
      interviewStatus: input.interviewStatus ? input.interviewStatus : null,
      mailStatus: input.mailStatus ? input.mailStatus : null,
      targetUsername: input.targetUsername,
    }

    const response = await this.sdk.fetchAttackLists(variables, this._headers())

    this._fetchAttackListsCursors = response.attackLists.pageInfo.endCursor

    return {
      attackLists: response.attackLists.nodes,
      totalCount: response.attackLists.totalCount,
      totalPages: response.attackLists.totalPages,
      currentPage: calcCurrentPage(response.attackLists.pageInfo.startCursor, input.limit),
      hasNextPage: response.attackLists.pageInfo.hasNextPage,
    }
  }

  async fetchDashboard(input: FetchDashboardRequestInput): Promise<IDashboardBase> {
    const response = await this.sdk.fetchDashboard(input, this._headers())

    return response.dashboard
  }

  async removeAttackLists(input: RemoveAttackListsRequestInput): Promise<IRemovedListBase> {
    const response = await this.sdk.removeAttackLists(
      {
        input: {
          targetUsername: input.targetUsername,
          attributes: input.attackLists,
        },
      },
      this._headers()
    )

    return response.removeAttackLists
  }

  // ============================================================
  // CorporateInvestors
  // ============================================================
  async fetchCorporateInvestors(
    input: FetchCorporateInvestorsRequestInput
  ): Promise<FetchCorporateInvestorsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else if (input.page) {
      after = calcAfterValue(input.page, input.limit)
    } else {
      after = this._fetchCorporateInvestorsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      query: input.searchWord ? input.searchWord : '',
      kinds: input.kinds ? input.kinds : [],
      investmentTargetMarketIds: input.investmentTargetMarketIds ? input.investmentTargetMarketIds : [],
      investmentTargetRoundIds: input.investmentTargetRoundIds ? input.investmentTargetRoundIds : [],
      locationIds: input.locationIds ? input.locationIds : [],
      investInLps: input.investInLps ? input.investInLps : null,
      investmentPurpose: input.investmentPurpose ? input.investmentPurpose : null,
      investmentTargetRegion: input.investmentTargetRegion ? input.investmentTargetRegion : null,
      targetUsername: input.targetUsername ? input.targetUsername : null,
      minPortfolioCount: input.minPortfolioCount ? Number(input.minPortfolioCount) : null,
      maxPortfolioCount: input.maxPortfolioCount ? Number(input.maxPortfolioCount) : null,
    }

    const response = await this.sdk.fetchCorporateInvestors(variables, this._headers())

    this._fetchCorporateInvestorsCursors = response.corporateInvestors.pageInfo.endCursor

    return {
      corporateInvestors: response.corporateInvestors.nodes,
      totalCount: response.corporateInvestors.totalCount,
      totalPages: response.corporateInvestors.totalPages,
      currentPage: calcCurrentPage(response.corporateInvestors.pageInfo.startCursor, input.limit),
      hasNextPage: response.corporateInvestors.pageInfo.hasNextPage,
    }
  }

  async fetchCorporateInvestor(input: FetchCorporateInvestorRequestInput): Promise<ICorporateInvestorBase> {
    const response = await this.sdk.fetchCorporateInvestor(
      {
        slug: input.slug,
        targetUsername: input.targetUsername,
      },
      this._headers()
    )

    this.credentials.storeCorporateInvestorSlug(input.slug)

    return response.corporateInvestor
  }

  async toggleCorporateInvestorList(input: ToggleCorporateInvestorListRequestInput): Promise<ICorporateInvestorBase> {
    const response = await this.sdk.toggleCorporateInvestorList(
      {
        input,
      },
      this._headers()
    )

    return response.toggleCorporateInvestorList
  }

  async updateCorporateInvestorList(input: UpdateCorporateInvestorListRequestInput): Promise<IAttackListEntityBase> {
    const response = await this.sdk.updateCorporateInvestorList(
      {
        input: {
          id: input.id,
          attributes: input.corporateInvestorList,
          targetUsername: input.targetUsername,
        },
      },
      this._headers()
    )

    return response.updateCorporateInvestorList
  }

  async addCorporateInvestorLists(input: AddCorporateInvestorListsRequestInput): Promise<IAddedListBase> {
    const response = await this.sdk.addCorporateInvestorLists(
      {
        input,
      },
      this._headers()
    )

    return response.addCorporateInvestorLists
  }

  // ============================================================
  // Angels
  // ============================================================
  async fetchAngels(input: FetchAngelsRequestInput): Promise<FetchAngelsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else if (input.page) {
      after = calcAfterValue(input.page, input.limit)
    } else {
      after = this._fetchAngelsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      query: input.searchWord ? input.searchWord : '',
      investmentTargetMarketIds: input.investmentTargetMarketIds ? input.investmentTargetMarketIds : [],
      investmentTargetRoundIds: input.investmentTargetRoundIds ? input.investmentTargetRoundIds : [],
      investInLps: input.investInLps ? input.investInLps : null,
      investmentPurpose: input.investmentPurpose ? input.investmentPurpose : null,
      investmentTargetRegion: input.investmentTargetRegion ? input.investmentTargetRegion : null,
      targetUsername: input.targetUsername,
    }

    const response = await this.sdk.fetchAngels(variables, this._headers())

    this._fetchAngelsCursors = response.angels.pageInfo.endCursor

    return {
      angels: response.angels.nodes,
      totalCount: response.angels.totalCount,
      totalPages: response.angels.totalPages,
      currentPage: calcCurrentPage(response.angels.pageInfo.startCursor, input.limit),
      hasNextPage: response.angels.pageInfo.hasNextPage,
    }
  }

  async fetchAngel(input: FetchAngelRequestInput): Promise<IAngelBase> {
    const response = await this.sdk.fetchAngel(
      {
        slug: input.slug,
        targetUsername: input.targetUsername,
      },
      this._headers()
    )

    return response.angel
  }

  async toggleAngelList(input: ToggleAngelListRequestInput): Promise<IAngelBase> {
    const response = await this.sdk.toggleAngelList(
      {
        input,
      },
      this._headers()
    )

    return response.toggleAngelList
  }

  async updateAngelList(input: UpdateAngelListRequestInput): Promise<IAttackListEntityBase> {
    const response = await this.sdk.updateAngelList(
      {
        input: {
          id: input.id,
          attributes: input.angelList,
          targetUsername: input.targetUsername,
        },
      },
      this._headers()
    )

    return response.updateAngelList
  }

  async addAngelLists(input: AddAngelListsRequestInput): Promise<IAddedListBase> {
    const response = await this.sdk.addAngelLists(
      {
        input,
      },
      this._headers()
    )

    return response.addAngelLists
  }

  // ============================================================
  // Markets
  // ============================================================
  async fetchMarkets(): Promise<IMarketBase[]> {
    const response = await this.sdk.fetchMarkets({}, this._headers())

    return response.markets
  }

  // ============================================================
  // Rounds
  // ============================================================
  async fetchRounds(): Promise<IRoundBase[]> {
    const response = await this.sdk.fetchRounds({}, this._headers())

    return response.rounds
  }

  // ============================================================
  // UserMembers
  // ============================================================
  async fetchUserMembers(input: FetchUserMembersRequestInput): Promise<IUserMemberBase[]> {
    const response = await this.sdk.fetchUserMembers(
      {
        after: '',
        before: '',
        first: 100,
        username: input.username,
      },
      this._headers()
    )

    return response.userMembers.nodes
  }

  async addUserMember(input: AddUserMemberRequestInput): Promise<IUserMemberBase> {
    const response = await this.sdk.addUserMember({ input }, this._headers())

    return response.addUserMember
  }

  async updateUserMember(input: UpdateUserMemberRequestInput): Promise<IUserMemberBase> {
    const response = await this.sdk.updateUserMember({ input }, this._headers())

    return response.updateUserMember
  }

  async removeUserMember(input: RemoveUserMemberRequestInput): Promise<IUserMemberBase> {
    const response = await this.sdk.removeUserMember({ input }, this._headers())

    return response.removeUserMember
  }

  // ============================================================
  // Users
  // ============================================================
  async searchUserByEmail(input: SearchUserByEmailRequestInput): Promise<IUserBase> {
    const response = await this.sdk.searchUserByEmail(
      {
        email: input.email,
      },
      this._headers()
    )

    return response.searchUserByEmail
  }

  async updateMyProfile(profile: IUserProfileInputBase): Promise<IUserProfileBase> {
    const response = await this.sdk.updateMyProfile(
      {
        input: {
          attributes: profile,
        },
      },
      this._headers()
    )

    return response.updateMyProfile
  }

  // ============================================================
  // Comments
  // ============================================================
  async fetchComments(input: FetchCommentsRequestInput): Promise<FetchCommentsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else if (input.page) {
      after = calcAfterValue(input.page, input.limit)
    } else {
      after = this._fetchCommentsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      query: input.searchWord ? input.searchWord : '',
      targetUsername: input.targetUsername,
    }

    const response = await this.sdk.fetchComments(variables, this._headers())

    this._fetchCommentsCursors = response.comments.pageInfo.endCursor

    return {
      comments: response.comments.nodes,
      totalCount: response.comments.totalCount,
      totalPages: response.comments.totalPages,
      currentPage: calcCurrentPage(response.comments.pageInfo.startCursor, input.limit),
      hasNextPage: response.comments.pageInfo.hasNextPage,
    }
  }

  async addCommentToCorporateInvestor(input: AddCommentToCorporateInvestorRequestInput): Promise<ICommentBase> {
    const response = await this.sdk.addCommentToCorporateInvestor({ input }, this._headers())

    return response.addCommentToCorporateInvestor
  }

  async updateCommentToCorporateInvestor(input: UpdateCommentToCorporateInvestorRequestInput): Promise<ICommentBase> {
    const response = await this.sdk.updateCommentToCorporateInvestor({ input }, this._headers())

    return response.updateCommentToCorporateInvestor
  }

  async removeCommentToCorporateInvestor(input: RemoveCommentToCorporateInvestorRequestInput): Promise<ICommentBase> {
    const response = await this.sdk.removeCommentToCorporateInvestor({ input }, this._headers())

    return response.removeCommentToCorporateInvestor
  }

  async addCommentToAngel(input: AddCommentToAngelRequestInput): Promise<ICommentBase> {
    const response = await this.sdk.addCommentToAngel({ input }, this._headers())

    return response.addCommentToAngel
  }

  async updateCommentToAngel(input: UpdateCommentToAngelRequestInput): Promise<ICommentBase> {
    const response = await this.sdk.updateCommentToAngel({ input }, this._headers())

    return response.updateCommentToAngel
  }

  async removeCommentToAngel(input: RemoveCommentToAngelRequestInput): Promise<ICommentBase> {
    const response = await this.sdk.removeCommentToAngel({ input }, this._headers())

    return response.removeCommentToAngel
  }

  // ============================================================
  // Invitations
  // ============================================================
  async addMemberInvitation(input: AddMemberInvitationRequestInput): Promise<IInvitationSentBase> {
    const response = await this.sdk.addMemberInvitation(
      {
        input: {
          targetUsername: input.targetUsername,
          days: input.days,
          email: input.email,
          role: input.role,
        },
      },
      this._headers()
    )

    return response.addMemberInvitation
  }

  async validInvitationToken(input: ValidInvitationTokenRequestInput): Promise<IInvitationBase> {
    const response = await this.sdk.validInvitationToken(input, this._headers())

    return response.validInvitationToken
  }

  // ============================================================
  // Subscription
  // ============================================================
  async createBillingPortalSession(input: CreateBillingPortalSessionInputBase): Promise<BillingPortalBase> {
    const response = await this.sdk.createBillingPortalSession(
      {
        input,
      },
      this._headers()
    )

    return response.createBillingPortalSession
  }

  async createDbSubscription(input: CreateDbSubscriptionInputBase): Promise<PaymentIntentBase> {
    const response = await this.sdk.createDbSubscription(
      {
        input,
      },
      this._headers()
    )

    return response.createDbSubscription
  }

  async createCheckoutSession(input: CreateCheckoutSessionInputBase): Promise<CheckoutSessionBase> {
    const response = await this.sdk.createCheckoutSession(
      {
        input,
      },
      this._headers()
    )

    return response.createCheckoutSession
  }

  // ============================================================
  // Locations
  // ============================================================
  async fetchLocations(input: FetchLocationsStoreInput): Promise<ILocationBase[]> {
    const response = await this.sdk.fetchLocations(
      {
        first: input.limit,
        query: input.searchWord,
      },
      this._headers()
    )

    return response.locations.nodes
  }
}
